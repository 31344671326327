export const isPRE = (name) => name.toUpperCase().includes('PREFIXADO');
export const isSELIC = (name) => name.toUpperCase().includes('SELIC');
export const isIPCA = (name) => name.toUpperCase().includes('IPCA');
export const isRENDA = (name) => name.toUpperCase().includes('RENDA');
export const isEDUCA = (name) => name.toUpperCase().includes('EDUCA');

export const getIndexer = (name) => {

    if (!name) return '';

    if (isSELIC(name)) return 'SELIC + ';
    if (isIPCA(name)) return 'IPCA + ';
    if (isRENDA(name)) return 'IPCA + ';
    if (isEDUCA(name)) return 'IPCA + ';

    return '';
}

export const sortByName = (a, b) => {

  const nameA = a.name;
  const nameB = b.name;

  if (isPRE(nameA) && !isPRE(nameB)) {
    return -1;
  }
  if (isPRE(nameB) && !isPRE(nameA)) {
    return 1;
  }

  if (isSELIC(nameA) && !isSELIC(nameB)) {
    return -1;
  }
  if (isSELIC(nameB) && !isSELIC(nameA)) {
    return 1;
  }
  
  if (isIPCA(nameA) && !isIPCA(nameB)) {
    return -1;
  }
  if (isIPCA(nameB) && !isIPCA(nameA)) {
    return 1;
  }

  if (isRENDA(nameA) && !isRENDA(nameB)) {
    return -1;
  }
  if (isRENDA(nameB) && !isRENDA(nameA)) {
    return 1;
  }

  if (isEDUCA(nameA) && !isEDUCA(nameB)) {
    return -1;
  }
  if (isEDUCA(nameB) && !isEDUCA(nameA)) {
    return 1;
  }

  return 0;
};